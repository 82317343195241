import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ReactHowler from "react-howler";
import Play from "../images/PLAY.svg";
import Pause from "../images/PAUSE.svg";

const PlayerWrapper = styled.section``;

const PlayButton = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  img {
    max-height: 40px;
  }
  &:focus {
    outline: 1px solid transparent;
  }
`;

const AudioPlayer = ({ audioSrc }) => {
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    return () => {
      setPlaying(false);
    };
  }, [audioSrc]);
  return (
    <PlayerWrapper>
      <PlayButton onClick={() => setPlaying(!playing)}>
        <img
          src={playing ? Pause : Play}
          alt={playing ? "press pause" : "press play"}
        />
      </PlayButton>
      <ReactHowler src={audioSrc} preload={true} playing={playing} />
    </PlayerWrapper>
  );
};

export default AudioPlayer;
