import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import SEO from "../components/seo";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import ArrowLeft from "../images/arrow-left-red.svg";
import ArrowRight from "../images/arrow-right-red.svg";
import AudioPlayer from "../components/AudioPlayer";

import "reset-css";

const PostWrapper = styled.main`
  padding: 0 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  text-align: center;
  top: 0%;
  left: 0;
  bottom: 0;
  right: 0;
  img {
    width: 100%;
    max-width: 100%;
  }
  @media (min-width: 1024px) {
    img {
      max-height: 66.6vh;
      width: auto;
    }
  }
`;

const ImageWrapper = styled.section`
  margin: 0 30px;

  @media (min-width: 1024px) {
    margin: 0 20px;
  }
`;

const AudioWrapper = styled.section`
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: calc(100% - 40px);
`;

const LeftButton = styled.button`
  position: fixed;
  top: 45%;
  bottom: 45%;
  left: 10px;
  border: 0;
  background-color: transparent;
  border-radius: 0;
  cursor: pointer;
  z-index: 5;
  padding: 0;
  &:focus {
    outline: 1px solid transparent;
  }

  img {
    max-width: 25px;
  }

  @media (min-width: 1024px) {
    left: 20px;

    img {
      max-width: 75px;
    }
  }
`;
const RightButton = styled(LeftButton)`
  right: 10px;
  left: auto;

  @media (min-width: 1024px) {
    right: 20px;
    left: auto;
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allSanitySiteSettings {
        nodes {
          postOrder {
            title
            image {
              asset {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
                url
              }
            }
            audio {
              asset {
                url
              }
            }
          }
        }
      }
    }
  `);

  const [posts] = useState(data.allSanitySiteSettings.nodes[0].postOrder);
  const [currentPost, setCurrentPost] = useState(
    data.allSanitySiteSettings.nodes[0].postOrder[0]
  );

  const toggleLeft = () => {
    const currentPosition = posts.indexOf(currentPost);
    const nextPosition = currentPosition - 1;

    nextPosition < 0
      ? setCurrentPost(posts[posts.length - 1])
      : setCurrentPost(posts[nextPosition]);
  };

  const toggleRight = () => {
    const currentPosition = posts.indexOf(currentPost);
    const nextPosition = currentPosition + 1;

    nextPosition > posts.length - 1
      ? setCurrentPost(posts[0])
      : setCurrentPost(posts[nextPosition]);
  };

  return (
    <Layout>
      <SEO title="<3 " />
      <LeftButton onClick={toggleLeft}>
        <img src={ArrowLeft} />
      </LeftButton>
      <PostWrapper>
        {currentPost && (
          <>
            <ImageWrapper>
              <img src={currentPost.image.asset.url} />
            </ImageWrapper>
            <AudioWrapper>
              <AudioPlayer audioSrc={currentPost.audio.asset.url} />
            </AudioWrapper>
          </>
        )}
      </PostWrapper>
      <RightButton onClick={toggleRight}>
        <img src={ArrowRight} />
      </RightButton>
    </Layout>
  );
};

export default IndexPage;
